<template>
  <div class="hall"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "./_hall.scss";
</style>
